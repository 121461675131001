<template>
    <div class="main">
        <a-modal :visible="visible" title="新建邮件" @cancel="visible=false" width="800px" :footer="null">
            <div class="modal">
                <div style="display: flex;margin-bottom: 10px;">
                    <div style="margin-right: 50px;">
                        发送邮箱:
                        <a-select size="small" v-model="account" show-search :filter-option="$selectFilterOption" placeholder="" style="width: 200px;">
                            <a-select-option v-for="item in emailList" :key="item.id" :value="item.type">{{
                            item.displayName }}</a-select-option>
                        </a-select>
                    </div>
                    <div>
                        发送方式:
                        <a-select size="small" :value="0" show-search :filter-option="$selectFilterOption" placeholder="" style="width: 200px;">
                            <a-select-option v-for="item in sendList" :key="item.value" :value="item.value">{{
                            item.name }}</a-select-option>
                        </a-select>
                    </div>  
                </div>
                <a-button @click="send()" type="primary" size="small" :loading="loading">开始发送</a-button>
                <div class="row" style="padding: 20px 0;border-bottom: 1px solid #ddd;">邮件主题: <a-input v-model="theme"></a-input></div>
                <div class="row" style="padding: 20px 0;border-bottom: 1px solid #ddd;">预览文本: <a-input v-model="previewText"></a-input></div>
                <div class="row" style="padding: 20px 0 0 0;"><a-button @click="importEmail()" type="primary" size="small" :loading="loadingA" >导入</a-button>
                    <input
                    ref="upload"
                    style="display: none"
                    type="file"
                    :multiple="false"
                    @change="onFileChange"
                    />
                    {{ file }}
                </div>
                <div style="max-height: 400px;overflow: auto;">
                    <div v-for="item in receiverList" :key="item.index" v-html="item.content" style="margin: 10px 0;border: 1px solid #ddd;padding: 10px">
                    </div>
                </div>
            </div>
        </a-modal>
        <div class="leftWrapper">
            <div class="header">
                <button @click="showDialog()">新建邮件</button>
            </div>
            <div class="content">
                <div v-for="(item, index) in list" class="list" :key="item.id" @click="showDetail(index)">
                    <div class="leftWrapper">
                        <div></div>
                        <div>{{item.count}}人</div>
                    </div>
                    <div class="rightWrapper">
                        <div class="line1">
                            <span class="title">{{item.theme}}</span>
                            <span>{{item.sendDate}}</span>
                        </div>
                        <div class="line2">{{item.previewText}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightWrapper">
            <div class="line1">
                <div class="title">发送状态</div>
                <div class="content">
                    <div class="row1">
                        <div>发送账号: {{ detail.account }}</div>
                        <div>发送任务数: {{ detail.count }}人</div>
                    </div>
                </div>
            </div>
            <div class="line2">
                <a-tabs
                    style="width: 100%;margin-left: 20px"
                    defaultActiveKey="0"
                >
                <a-tab-pane key="0" tab="发送结果" class="tab1">
                    <div v-for="item in detail.subList" :key="item.id" class="list">
                        <div>开始时间: {{ item.sendTime }}</div>
                        <div>{{ item.receiveMail }}</div>
                        <div>
                            <span v-if="item.status">发送成功</span>
                            <span v-else>发送失败</span>
                        </div>
                    </div>
                    
                </a-tab-pane>
                <a-tab-pane key="1" tab="邮件内容" class="tab2">
                    <div class="row">邮件主题: {{ detail.theme }}</div>
                    <div class="row">预览文本: {{ detail.previewText }}</div>
                    <div class="row" style="border: none;">发送内容: {{ detail.content }}</div>
                </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import request from "@/api/request";
function fetchList(data) {
    return request({
        url: "/office-service/mail/list",
        method: "post",
        data
    });
}
function importMulEmail(data) {
    return request({
        url: "/office-service/mail/importMulEmail",
        method: "post",
        data
    });
}
function getMailList() {
    return request({
        url: "/office-service/mail/getMailList",
    });
}
function sendMail(data) {
    return request({
        url: "/office-service/mail/sendMulMail",
        method: "post",
        data
    });
}
export default {
    name: "mail",
    data() {
        return {
            account: '',
            previewText: '',
            theme: '',
            receiverList: [],
            emailList: [],
            sendList: [
                {
                    name:'群发工资条',
                    value:0
                }
            ],
            loading: false,
            loadingA: false,
            list:[],
            detail: {},
            visible: false,
            file: ''
        };
    },
    methods:{
        showDetail(index){
            this.detail = this.list[index];
        },
        showDialog(){
            this.visible = true
        },
        send(){
            this.loading=true;
            sendMail({
                mailType: this.account,
                account: this.emailList.filter(v=>v.type == this.account)[0].username,
                previewText: this.previewText,
                theme: this.theme,
                receiverList: this.receiverList 
            }).then(() => {
                this.getList();
                this.visible = false;
            }).finally(() => {
                this.loading = false;
            });
        },
        importEmail(){
            this.$refs.upload.click();
        },
        onFileChange(e) {
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }
            this.file = files[0].name
            console.log(this.file)
            this.loadingA = true;

            importMulEmail(data)
                .then((res) => {
                    console.log("upload res", res);
                    this.receiverList = res.email
                })
                .finally(() => {
                    this.loadingA = false;
                    e.target.value = null;
                });
        },
        getList(){
            fetchList({
                pageNum:0,
                pageSize: 9999 
            }).then((res) => {
                this.list=res.list;
                if(this.list.length > 0){
                    this.detail = this.list[0];
                }
            })
            .finally(() => {
            });
        }
    },
    mounted() {
        this.getList();
        getMailList().then((res) => {
            this.emailList=res;
        })
    }
}
</script>

<style lang="less" scoped>
.main {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    >.leftWrapper {
        width: 300px;
        margin-right: 20px;
        border: 1px solid #ddd;
        margin-bottom: -20px;
        .header{
            button{
                width: 100%;
                height: 30px;
                cursor: pointer;
            }
        }
        .content{
            height: calc(100vh - 270px);
            overflow-y: auto;
            .list{
                cursor: pointer;
                border-bottom: 1px solid #ddd;
                padding: 20px 10px;
                display: flex;
                justify-content: space-between;
                .leftWrapper{
                    width: 50px;
                    align-items: center;
                    display: flex;
                }
                .rightWrapper{
                    flex: 1;
                    .line1{
                        display: flex;
                        margin-bottom: 10px;
                        justify-content: space-between;
                        .title{
                            font-weight: bold;
                        }
                    }
                }
            }
            .list:hover{
                background-color: #dddddd36;
            }
        }
    }

    >.rightWrapper {
        flex: 1;
        
        .line1{
            border: 1px solid #ddd;
            height: 30%;
            .title{
                border-bottom: 1px solid #ddd;
                padding: 20px 10px 10px 20px;
            }
            .content{
                padding: 30px 10px 10px 20px;
                .row1{
                    display: flex;
                    gap: 40px;
                }
            }
        }
        .line2{
            height: 70%;
            border: 1px solid #ddd;
            margin-top: 20px;
            .tab1{
                padding: 30px 10px 10px 10px;
            }
            .list{
                display:flex;
                gap: 20px 40px;
            }
            .tab2, .modal{
                padding: 10px 10px 10px 10px;
                .row{
                    padding: 20px 10px;
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }
}
</style>